a.dropdown-item {
	overflow: hidden;
	text-overflow: ellipsis;
}
.searchBox {
	border:1px solid #EBEBD3 !important;
	height: 50px !important;
}
@media (max-width: 576px) {
	a.dropdown-item {
		padding: 0.25rem 0.5rem !important;
	}
}
